import React from 'react'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  styled,
  Box,
  Typography,
  CircularProgress,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import { unserializeCompetenceLocal, unserializeLocal } from '../../config'
import { useSelector } from 'react-redux'
import moment from 'moment-timezone'
import { useTranslation } from 'react-i18next'

const HeaderTab = styled(TableHead)(({ theme }) => ({
  height: 60,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const TitleTab = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: 14,
  },
}))

const BodyTab = styled(TableCell)(({ theme, type }) => ({
  borderBottom:
    type === 'entreprise'
      ? `1px solid ${lightTheme.palette.secondary.main}`
      : type === 'candidat'
      ? `1px solid ${lightTheme.palette.warning.dark}`
      : `1px solid ${lightTheme.palette.secondary.main}`,
}))

const BlockTabCircle = styled(TableCell)(({ theme }) => ({
  width: 25,
  /*height: 61,*/
  height: '100%',
  backgroundColor: lightTheme.palette.secondary.main,
  padding: 0,
  margin: 0,
  borderBottom: `1px solid ${lightTheme.palette.secondary.main}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '5px',
  position: 'absolute',

  '.firstCircle, .secondCircle, .thirdCircle, .fourthCircle, .fiveCircle': {
    width: 5,
    height: 5,
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
  },
}))

const BlockTabSquare = styled(TableCell)(({ theme }) => ({
  width: 25,
  /*height: 61,*/
  height: '100%',
  backgroundColor: lightTheme.palette.warning.dark,
  padding: 0,
  margin: 0,
  borderBottom: `1px solid ${lightTheme.palette.warning.dark}`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '5px',
  position: 'absolute',

  '.firstSquare, .secondSquare, .thirdSquare, .fourthSquare, .fiveSquare': {
    width: 5,
    height: 5,
    backgroundColor: '#FFFFFF',
  },
}))

const BlockTab = styled(TableCell)(({ theme, type }) => ({
  width: 15,
  /*height: 60,*/
  height: '100%',
  padding: 0,
  backgroundColor:
    type === 'entreprise'
      ? lightTheme.palette.secondary.main
      : type === 'candidat'
      ? lightTheme.palette.warning.dark
      : lightTheme.palette.secondary.main,
  borderBottom:
    type === 'entreprise'
      ? `1px solid ${lightTheme.palette.secondary.main}`
      : type === 'candidat'
      ? `1px solid ${lightTheme.palette.warning.dark}`
      : `1px solid ${lightTheme.palette.secondary.main}`,
}))

const ButtonIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.primary.b2}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const DeleteIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.error.dark}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.error.dark}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const EmptyText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic',
  fontSize: 14,
  lineHeight: '140%',
  color: lightTheme.palette.grey.main,
  textAlign: 'center',
  fontStyle: 'italic',
  padding: '15px 0',
  background: 'rgba(0, 0, 0, 0.04)',
}))

const ListTable = ({
  type,
  items,
  setId,
  loading,
  handleClick,
  handleRemove,
  listeMatching = false,
  matchingPost = '',
  isAdmin = false,
  ids,
}) => {
  const { t, i18n } = useTranslation()
  const userData = useSelector((state) => state.userAuth.user)
  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '40vh',
        }}
      >
        <CircularProgress />
      </Box>
    )

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
          borderRadius: 0,
        }}
      >
        <Table>
          <HeaderTab>
            <TableRow>
              <TableCell width={25} sx={{ padding: 0 }} />
              {type === 'entreprise' ? (
                <>
                  <TitleTab>{t('header.company')}</TitleTab>
                  <TitleTab>{t('contact.lastName')}</TitleTab>
                  <TitleTab>{t('contact.firstName')}</TitleTab>
                  <TitleTab sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    {t('contact.email')}
                  </TitleTab>
                  <TitleTab sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    {t('header.offer')}
                  </TitleTab>
                  <TitleTab sx={{ display: { xs: 'none', sm: 'table-cell' } }}>
                    {t('profile.date')}
                  </TitleTab>
                </>
              ) : (
                <>
                  {listeMatching && (
                    <TitleTab>{t('header.candidate')}</TitleTab>
                  )}
                  {(!listeMatching || isAdmin) && (
                    <TitleTab>{t('contact.lastName')}</TitleTab>
                  )}
                  {(!listeMatching || isAdmin) && (
                    <TitleTab>{t('contact.firstName')}</TitleTab>
                  )}
                  <TitleTab>{t('filter.poste')}</TitleTab>
                  {!listeMatching && (
                    <TitleTab
                      sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                    >
                      {t('contact.email')}
                    </TitleTab>
                  )}
                  {!listeMatching && (
                    <>
                      <TitleTab
                        sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                      >
                        {t('header.offer')}
                      </TitleTab>
                      <TitleTab
                        sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                      >
                        {t('profile.date')}
                      </TitleTab>
                    </>
                  )}
                  {listeMatching && (
                    <>
                      <TitleTab>{t('filter.competences')}</TitleTab>
                      {/* <TitleTab>{t('filter.competencesAnnexes')}</TitleTab> */}
                      <TitleTab>{t('filter.languages')}</TitleTab>
                      <TitleTab>{t('filter.matching')}</TitleTab>
                    </>
                  )}
                </>
              )}

              <TableCell />
              <TableCell width={15} sx={{ padding: 0 }} />
            </TableRow>
          </HeaderTab>
          <TableBody>
            {items?.length > 0 && (
              <>
                {items.map((item, index) => (
                  <TableRow key={index} sx={{ position: 'relative', textDecoration: ids && ids.includes(item.profile_id) ? 'line-through' : '' }}>
                    {type === 'entreprise' ? (
                      <>
                        <BlockTabCircle>
                          <div className={'firstCircle'} />
                          <div className={'secondCircle'} />
                          <div className={'thirdCircle'} />
                          <div className={'fourthCircle'} />
                          <div className={'fiveCircle'} />
                        </BlockTabCircle>
                        <BodyTab type={type}>
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                              textTransform: 'capitalize',
                            }}
                          >
                            {item.entreprise.raisonSocial}
                          </Typography>
                        </BodyTab>
                        <BodyTab
                          type={type}
                          sx={{ textTransform: 'Capitalize' }}
                        >
                          {item.nom}
                        </BodyTab>
                        <BodyTab
                          type={type}
                          sx={{ textTransform: 'Capitalize' }}
                        >
                          {item.prenom}
                        </BodyTab>
                        <BodyTab
                          type={type}
                          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                        >
                          <Typography
                            sx={{
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                              display: '-webkit-box',
                              WebkitLineClamp: '1',
                              WebkitBoxOrient: 'vertical',
                            }}
                          >
                            {item.email}
                          </Typography>
                        </BodyTab>
                        <BodyTab
                          type={type}
                          sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                        >
                          {item.entrepriseOffres.length > 0 && (
                            <Typography
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              {item.entrepriseOffres.map(obj => i18n.language === 'fr' ? obj.offre.titre : obj.offre.titreEn).join(', ')}
                            </Typography>
                          )}
                        </BodyTab>
                        <BodyTab type={type}>
                          {moment(item.date)
                            .tz('Europe/Paris')
                            .format('DD/MM/YYYY') +
                            ' ' +
                            moment(item.date)
                              .tz('Europe/Paris')
                              .format('HH:mm')}
                        </BodyTab>
                      </>
                    ) : (
                      <>
                        <BlockTabSquare>
                          <div className={'firstSquare'} />
                          <div className={'secondSquare'} />
                          <div className={'thirdSquare'} />
                          <div className={'fourthSquare'} />
                          <div className={'fiveSquare'} />
                        </BlockTabSquare>
                        {listeMatching && (
                          <BodyTab type={type}>#Jober000{item.idcandidat}</BodyTab>
                        )}
                        {(!listeMatching || isAdmin) && (
                          <BodyTab type={type}>
                            <Typography
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                                textTransform: 'Capitalize',
                              }}
                            >
                              {item.nom}
                            </Typography>
                          </BodyTab>
                        )}
                        {(!listeMatching || isAdmin) && (
                          <BodyTab type={type}>{item.prenom}</BodyTab>
                        )}
                        <BodyTab type={type}>
                          {listeMatching && (
                            <Typography
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              {matchingPost}
                            </Typography>
                          )}
                          {item.candidat?.poste && (
                            <Typography
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: '-webkit-box',
                                WebkitLineClamp: '1',
                                WebkitBoxOrient: 'vertical',
                              }}
                            >
                              {i18n.language === 'fr'
                                ? item.candidat.poste.titre
                                : item.candidat.poste.titreEn}
                            </Typography>
                          )}
                        </BodyTab>
                        {!listeMatching && (
                          <>
                            <BodyTab
                              type={type}
                              sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                            >
                              <Typography
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: '1',
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {item.email}
                              </Typography>
                            </BodyTab>
                            <BodyTab
                              type={type}
                              sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                            >
                              {item.userOffres[0] && (
                                <>
                                  <Typography
                                    sx={{
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      display: '-webkit-box',
                                      WebkitLineClamp: '1',
                                      WebkitBoxOrient: 'vertical',
                                    }}
                                  >
                                    {item.userOffres[0].offre.titre}
                                  </Typography>
                                </>
                              )}
                            </BodyTab>
                            <BodyTab type={type}>
                              {moment(item.date)
                                .tz('Europe/Paris')
                                .format('DD/MM/YYYY') +
                                ' ' +
                                moment(item.date)
                                  .tz('Europe/Paris')
                                  .format('HH:mm')}
                            </BodyTab>
                          </>
                        )}
                        {listeMatching && ( 
                          <>
                            <BodyTab
                              type={type}
                              sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                            >
                              <Typography
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: '1',
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {unserializeCompetenceLocal(item.competences) &&
                                  unserializeCompetenceLocal(item.competences).map(obj => obj.competence).join(', ')}
                              </Typography>
                            </BodyTab>
                            {/* <BodyTab
                              type={type}
                              sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                            >
                              <Typography
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: '1',
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {unserializeLocal(item.competencesAnnexes) &&
                                  unserializeLocal(item.competencesAnnexes).join(', ')} 
                              </Typography>
                            </BodyTab> */}
                            <BodyTab
                              type={type}
                              sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                            >
                              <Typography
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: '1',
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {unserializeLocal(item.langues) &&
                                  unserializeLocal(item.langues).join(', ')}
                              </Typography>
                            </BodyTab>
                            <BodyTab
                              type={type}
                              sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                            >
                              <Typography
                                sx={{
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                  display: '-webkit-box',
                                  WebkitLineClamp: '1',
                                  WebkitBoxOrient: 'vertical',
                                }}
                              >
                                {parseFloat(item.matching_percentage).toFixed(
                                  2
                                )}{' '}
                                %
                              </Typography>
                            </BodyTab>
                          </>
                        )}
                      </>
                    )}

                    <BodyTab
                      type={type}
                      sx={{
                        padding: '10px',
                        width: listeMatching ? 'auto' : '130px',
                      }}
                    >
                      <ButtonIcon
                        onClick={() => {
                          setId(item.id)
                          /*handleClick(`${item.nom} ${item.prenom}`);*/
                          handleClick(`${item.id}`, item)
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                          color={lightTheme.palette.primary.b2}
                          fontSize={16}
                        />
                      </ButtonIcon>
                      {!listeMatching &&
                        (userData.data.role === 'ROLE_ADMIN' ||
                          userData.data.role === 'ROLE_SUPER_ADMIN') && (
                          <DeleteIcon
                            onClick={(e) => {
                              handleRemove(item.id)
                              //e.target.parentNode.parentNode.parentNode.remove()
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              color={lightTheme.palette.error.dark}
                              fontSize={16}
                            />
                          </DeleteIcon>
                        )}
                    </BodyTab>
                    <BlockTab type={type} />
                  </TableRow>
                ))}
              </>
            )}
          </TableBody>
        </Table>
        {!items?.length > 0 && (
          <EmptyText>
            pas {type === 'entreprise' ? " d'entreprises" : 'de candidats'}{' '}
            ici...
          </EmptyText>
        )}
      </TableContainer>

      {/*<AppPagination setProducts={(p) => setProducts(p)}/>*/}
    </>
  )
}

export default ListTable
