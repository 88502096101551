import React from 'react'
import {
  Box,
  Container,
  Grid,
  Paper,
  styled,
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faFilePlus } from '@fortawesome/pro-regular-svg-icons'
import '../../styles/components/_box.scss'
import lock from '../../assets/images/lock.png'
import { CustomButton } from '../buttons'
import moment from 'moment-timezone'
import { niveauCompetencesList } from '../../data'

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  height: 435,
  borderRadius: 0,
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.05)',
  padding: '15px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  rowGap: '20px',
}))

const BodyTable = styled(TableBody)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: '2px solid rgb(227, 230, 255, 0.5)',
  fontFamily: 'Century Gothic',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const HeaderTab = styled(TableHead)(({ theme }) => ({
  height: 50,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const TitleHead = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const ItemLong = styled(Paper)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
  padding: '15px',
}))

const BannerCircle = styled(Box)(({ theme }) => ({
  backgroundColor: lightTheme.palette.secondary.main,
  height: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '30px',

  '.firstCircle, .secondCircle, .thirdCircle, .fourthCircle, .fiveCircle': {
    width: 10,
    height: 10,
    backgroundColor: lightTheme.palette.secondary.main,
    border: '1px solid #FFFFFF',
    borderRadius: '50%',
  },
}))

const TitleBox = styled(Typography)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 14,
  lineHeight: '30px',
  letterSpacing: '0.4px',
  color: lightTheme.palette.text.primary,
  textTransform: 'uppercase',
  minHeight: '30px',
}))

const TitleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}))

const IconContainer = styled(Box)(({ theme }) => ({
  width: 30,
  height: 30,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',

  '&:hover': {
    borderWidth: '2px',
  },

  '&:active': {
    borderWidth: '1px',
  },
}))

const WrapperIcons = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  columnGap: '8px',
}))

const BannerSquare = styled(Box)(({ theme }) => ({
  backgroundColor: lightTheme.palette.warning.dark,
  height: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  columnGap: '30px',

  '.firstSquare, .secondSquare, .thirdSquare, .fourthSquare, .fiveSquare': {
    width: 10,
    height: 10,
    backgroundColor: '#FFFFFF',
    /*border: "1px solid #FFFFFF",
    borderRadius: "50%"*/
  },
}))

const MasonryLayout = ({
  profil,
  besoinClient,
  resumeClient,
  commentaireAdmin,
  rendezVous,
  offre,
  ged,
  relation,
  valeurs,
  competences,
  competencesAnnexes,
  softskils,
  type,
  displayValeurSection,
  displayCompetencesSection,
  displaySoftskilSection,
  handleOpenDoc,
  handleOpenBesoin,
  handleOpenCommentaire,
  handleOpenResume,
  handleOpenCompetences,
  handleOpenCompetencesAnnexes,
  handleOpenValeurs,
  handleOpenSoftskils,
  handleOpenOffer,
  displayParams = true,
  feedback,
}) => {
  return (
    <Container
      maxWidth={'lg'}
      sx={{ paddingTop: '25px', paddingBottom: '25px' }}
    >
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} lg={6} order={{ sm: 1, lg: 1 }}>
          {type === 'entreprise' ? (
            <BannerCircle
              sx={{
                display: { xs: 'none', lg: 'flex' },
              }}
            >
              <TitleBox style={{ color: '#fff' }}>Espace Entreprise</TitleBox>
            </BannerCircle>
          ) : (
            <BannerSquare
              sx={{
                display: { xs: 'none', lg: 'flex' },
              }}
            >
              <TitleBox style={{ color: '#fff' }}>Espace Candidat</TitleBox>
            </BannerSquare>
          )}

          <Item
            sx={{
              height: { sm: '100%', lg: 'calc(100% - 50px)' },
            }}
          >
            {profil}
          </Item>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          lg={6}
          order={{ sm: 6, lg: 2 }}
          position={'relative'}
        >
          <Item
            sx={{
              height: '100%',
            }}
            /*className={"blurLayout"}*/
          >
            <TitleContainer>
              <TitleBox>Rendez-vous</TitleBox>
              {/*<IconContainer>
                <FontAwesomeIcon icon={faCalendarPlus} fontSize={15} color={lightTheme.palette.primary.b2}/>
              </IconContainer>*/}
            </TitleContainer>

            <Box>{rendezVous}</Box>
          </Item>
          {/*<img
            src={lock}
            alt={"Jobertech - not access"}
            style={{
              width: 150,
              height: 150,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-40%, -45%)"
            }}
          />*/}
        </Grid>
        <Grid item xs={12} sm={6} lg={6} order={{ sm: 2, lg: 3 }}>
          <Item
            sx={{
              height: '100%',
            }}
          >
            <TitleContainer>
              <TitleBox>
                {type === 'entreprise' ? (
                  <>Fiches de poste</>
                ) : (
                  <>Recherche candidat</>
                )}
              </TitleBox>
              {displayParams && (
                <WrapperIcons>
                  <IconContainer onClick={handleOpenBesoin}>
                    <FontAwesomeIcon
                      icon={faPen}
                      fontSize={15}
                      color={lightTheme.palette.primary.b2}
                    />
                  </IconContainer>
                </WrapperIcons>
              )}
            </TitleContainer>

            <Box>{besoinClient}</Box>
          </Item>
        </Grid>
        <Grid item sm={8} lg={6} order={{ sm: 3, lg: 4 }} position={'relative'}>
          <Item
            sx={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              rowGap: '20px',
              justifyContent: 'space-between',
            }}
            className={offre ? '' : ''}
          >
            <TitleContainer>
              <TitleBox>Historique des achats</TitleBox>
            </TitleContainer>

            <Box overflow={'auto'} width={'100%'} height={'100%'}>
              {offre}
            </Box>
            {displayParams && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  rowGap: '15px',
                }}
              >
                <CustomButton
                  displayIcon={'none'}
                  handleClick={handleOpenOffer}
                >
                  Proposer une offre
                </CustomButton>
              </Box>
            )}
          </Item>
          {/* {!offre && (
            <img
              src={lock}
              alt={'Jobertech - not access'}
              style={{
                width: 150,
                height: 150,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-40%, -45%)',
              }}
            />
          )}*/}
        </Grid>
        <Grid
          item
          xs={12}
          sm={8}
          lg={12}
          order={{ sm: 5, lg: 5 }}
          position={'relative'}
        >
          <Item
            sx={{
              height: { sm: 400, lg: 435 },
            }}
            className={ged ? '' : 'blurLayout'}
          >
            <TitleContainer>
              <TitleBox>GED</TitleBox>
              {displayParams && (
                <WrapperIcons>
                  <IconContainer onClick={handleOpenDoc}>
                    <FontAwesomeIcon
                      icon={faFilePlus}
                      fontSize={15}
                      color={lightTheme.palette.primary.b2}
                    />
                  </IconContainer>
                </WrapperIcons>
              )}
            </TitleContainer>

            <Box overflow={'auto'} width={'100%'} height={'100%'}>
              {ged}
            </Box>
          </Item>

          {!ged && (
            <img
              src={lock}
              alt={'Jobertech - not access'}
              style={{
                width: 150,
                height: 150,
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-40%, -45%)',
              }}
            />
          )}
        </Grid>
        {/* {type !== 'entreprise' && (
          <Grid
            item
            xs={12}
            sm={4}
            lg={3.5}
            order={{ sm: 4, lg: 6 }}
            position={'relative'}
          >
            <Item
              sx={{
                height: { sm: 400, lg: 435 },
              }}
              /* className={"blurLayout"} 
            >
              <TitleBox>Mise en relation</TitleBox>

              <Box>{relation}</Box>
            </Item>
            {/*  <img
            src={lock}
            alt={"Jobertech - not access"}
            style={{
              width: 150,
              height: 150,
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-40%, -45%)"
            }}
          /> *
          </Grid>
        )} */}

        <Grid item xs={12} sm={12} order={{ sm: 7 }}>
          <Item
            sx={{
              height: '100%',
            }}
          >
            <TitleContainer>
              <TitleBox>Feedbacks</TitleBox>
            </TitleContainer>
            <Box>
              <TableContainer>
                <Table>
                  <HeaderTab>
                    <TableRow>
                      <TitleHead>Entreprise</TitleHead>
                      <TitleHead>Date d’entretien</TitleHead>
                      <TitleHead>Feedback</TitleHead>
                    </TableRow>
                  </HeaderTab>
                  {feedback &&
                    feedback.map((rdv, i) => (
                      <BodyTable key={i}>
                        {rdv.feedbackEntreprise && (
                          <TableRow>
                            <StyledTableCell>
                              {rdv.entreprise.raisonSocial}
                            </StyledTableCell>
                            <StyledTableCell>
                              {moment(rdv.dateDebut)
                                .tz('Europe/Paris')
                                .format('DD/MM/YYYY HH:mm')}
                            </StyledTableCell>
                            <StyledTableCell>
                              {rdv.feedbackEntreprise}
                            </StyledTableCell>
                          </TableRow>
                        )}
                      </BodyTable>
                    ))}
                </Table>
              </TableContainer>
            </Box>
          </Item>
        </Grid>
        <Grid item xs={12} sm={12} order={{ sm: 7 }}>
          <Item
            sx={{
              height: '100%',
            }}
          >
            <TitleContainer>
              <TitleBox>Commentaires jobertech</TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenCommentaire}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>

            <Box>{commentaireAdmin}</Box>
          </Item>
        </Grid>
        {type !== 'entreprise' && (
          <Grid item xs={12} sm={12} order={{ sm: 7 }}>
            <Item
              sx={{
                height: '100%',
              }}
            >
              <TitleContainer>
                <TitleBox>Résumé du candidat</TitleBox>
                <WrapperIcons>
                  <IconContainer onClick={handleOpenResume}>
                    <FontAwesomeIcon
                      icon={faPen}
                      fontSize={15}
                      color={lightTheme.palette.primary.b2}
                    />
                  </IconContainer>
                </WrapperIcons>
              </TitleContainer>

              <Box>{resumeClient}</Box>
            </Item>
          </Grid>
        )}
        <Grid
          item
          xs={12}
          sm={12}
          order={{ sm: 7 }}
          display={displayCompetencesSection}
        >
          <ItemLong>
            <TitleContainer>
              <TitleBox>compétences techniques ou métiers principales</TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenCompetences}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>
            <Box>
              <ul className="list-values">
                {competences &&
                  competences.map((competence, i) => (
                    <li key={i}>
                      {competence.competence} :{' '}
                      <b>
                        {
                          niveauCompetencesList[competence.niveau - 1].titre[
                            'fr'
                          ]
                        }
                      </b>
                    </li>
                  ))}
              </ul>
            </Box>
          </ItemLong>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          order={{ sm: 7 }}
          display={displayCompetencesSection}
        >
          <ItemLong>
            <TitleContainer>
              <TitleBox>Compétences techniques annexes</TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenCompetencesAnnexes}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>
            <Box>
              <ul className="list-values">
                {competencesAnnexes &&
                  competencesAnnexes.map((competence, i) => (
                    <li key={i}>
                      {competence.competence} :{' '}
                      <b>
                        {
                          niveauCompetencesList[competence.niveau - 1].titre[
                            'fr'
                          ]
                        }
                      </b>
                    </li>
                  ))}
              </ul>
            </Box>
          </ItemLong>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          order={{ sm: 8 }}
          display={displaySoftskilSection}
        >
          <ItemLong>
            <TitleContainer>
              <TitleBox>Compétences personnelles</TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenSoftskils}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>
            <Box>
              <ul className="list-values">
                {softskils &&
                  softskils.map((softskil, i) => <li>{softskil}</li>)}
              </ul>
            </Box>
          </ItemLong>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          order={{ sm: 9 }}
          display={displayValeurSection}
        >
          <ItemLong>
            <TitleContainer>
              <TitleBox>Valeurs</TitleBox>
              <WrapperIcons>
                <IconContainer onClick={handleOpenValeurs}>
                  <FontAwesomeIcon
                    icon={faPen}
                    fontSize={15}
                    color={lightTheme.palette.primary.b2}
                  />
                </IconContainer>
              </WrapperIcons>
            </TitleContainer>
            <Box>
              <ul className="list-values">
                {valeurs && valeurs.map((valeur, i) => <li>{valeur}</li>)}
              </ul>
            </Box>
          </ItemLong>
        </Grid>
      </Grid>
    </Container>
  )
}

export default MasonryLayout
