import React, { useState } from 'react'
import {
  Box,
  CircularProgress,
  Grid,
  Modal,
  styled,
  TextField,
  Typography,
} from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import StripeAppTest from '../Stripe/StripeAppTest'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck, faClose } from '@fortawesome/pro-solid-svg-icons'
import { useTranslation } from 'react-i18next'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    /*height: 500,*/
    height: 'auto',
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    /*height: 300,*/
    height: 'auto',
  },
}))

const PaymentModal = ({
  open,
  onClose,
  onSuccess,
  userId,
  userOffre,
  typeOffre,
  role,
}) => {
  
  const { t } = useTranslation()
  const [codePromo, setCodePromo] = useState('')
  const [loading, setLoading] = useState(false)
  const [errorCodePromo, setErrorCodePromo] = useState(false)
  const [offreUtilisateur, setOffreUtilisateur] = useState(userOffre)

  const handleCodePromo = async () => {
    setLoading(true)
    setErrorCodePromo(false)
    try {
      const result = await Api.offerUtilisateur.edit(
        offreUtilisateur.id,
        {
          codePromoTexte: codePromo,
        },
        role
      )

      const handling = handlingErrors(result)

      if (handling.ok) {
        const res = handling.data
        setOffreUtilisateur(res)
        setCodePromo('')
        //onSuccess()
      } else {
        setErrorCodePromo(true)
      }
    } catch (error) {
      console.log(error)
      setErrorCodePromo(true)
    }
    setLoading(false)
  }

  const handleCodePromoAtelier = async () => {
    setLoading(true)
    setErrorCodePromo(false)
    try {
      const result = await Api.ateliersUtilisateurs.edit(offreUtilisateur.id, {
        codePromoTexte: codePromo,
      })

      const handling = handlingErrors(result)

      if (handling.ok) {
        const res = handling.data
        setOffreUtilisateur(res)
        setCodePromo('')
        //onSuccess()
      } else {
        setErrorCodePromo(true)
      }
    } catch (error) {
      console.log(error)
      setErrorCodePromo(true)
    }
    setLoading(false)
  }
  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {typeOffre === 'atelier'
            ? t('box.title.paimentAtelier')
            : t('box.title.paimentOffer')}
          <Box
            sx={{
              position: 'absolute',
              right: '10px',
              top: '10px',
              cursor: 'pointer',
            }}
          >
            <FontAwesomeIcon icon={faClose} fontSize={25} onClick={onClose} />
          </Box>
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '20px',
            textAlign: 'center',
          }}
        >
          {typeOffre === 'atelier' ? (
            <Typography variant={'subtitle2'}>
              {offreUtilisateur?.atelier?.titre}
              <br></br>
              {offreUtilisateur?.atelier?.sousTitre}
            </Typography>
          ) : (
            <Typography variant={'subtitle2'}>
              {offreUtilisateur?.offre?.titre}
              <br></br>
              {offreUtilisateur?.offre?.sousTitre}
            </Typography>
          )}

          <Typography variant={'body2'}>
            {offreUtilisateur?.codePromo && (
              <>
                {t('profile.price')} :{' '}
                {(
                  offreUtilisateur?.prix *
                  JSON.parse(sessionStorage.getItem('currency')).currentRate
                ).toFixed(2)}{' '}
                {JSON.parse(sessionStorage.getItem('currency')).devise}{' '}
                <br></br>
              </>
            )}
            {offreUtilisateur?.codePromo
              ? t('profile.firstPrice')
              : t('profile.price')}{' '}
            :{' '}
            <b>
              {(
                offreUtilisateur?.prixPromo *
                JSON.parse(sessionStorage.getItem('currency')).currentRate
              ).toFixed(2)}{' '}
              {JSON.parse(sessionStorage.getItem('currency')).devise}
            </b>
          </Typography>
        </Box>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{
            width: 250,
            maxWidth: '100%',
            textAlign: 'center',
          }}
        >
          {offreUtilisateur?.codePromo ? (
            <Typography variant={'p'}>{t('profile.applyCodePromo')}</Typography>
          ) : (
            <TextField
              fullWidth
              id="standard-name"
              label={t('profile.haveYouCodePromo')}
              placeholder={t('profile.enterCodePromo')}
              variant="standard"
              value={codePromo}
              error={errorCodePromo && true}
              helperText={errorCodePromo && t('errorMessage.codePromo')}
              onChange={(event) => {
                setCodePromo(event.target.value)
                setErrorCodePromo(false)
              }}
              InputProps={{
                endAdornment:
                  codePromo && !loading ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      fontSize={20}
                      style={{ cursor: 'pointer' }}
                      onClick={
                        typeOffre === 'atelier'
                          ? handleCodePromoAtelier
                          : handleCodePromo
                      }
                    />
                  ) : loading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <></>
                  ),
              }}
            />
          )}
        </Grid>
        <BoxContainer>
          <Grid
            container
            spacing={2}
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'row' },
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
            >
              <StripeAppTest
                userId={userId}
                offreId={
                  typeOffre === 'atelier'
                    ? offreUtilisateur?.atelier?.id
                    : offreUtilisateur?.id
                }
                offrePrice={(
                  offreUtilisateur?.prixPromo *
                  JSON.parse(sessionStorage.getItem('currency')).currentRate
                ).toFixed(2)}
                offrePeriode={offreUtilisateur?.periode}
                onSuccess={onSuccess}
                onClose={onClose}
                type={typeOffre}
              />
            </Grid>
          </Grid>
        </BoxContainer>
      </ModalContainer>
    </Modal>
  )
}

export default PaymentModal
