import { MenuItem, Select, styled } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons'
import React from 'react'

const CustomSelect = styled(Select)(({ theme, type }) => ({
  width: '100%',
  height: 45,
  borderWidth: type === 'border' ? '1px' : 0,
  borderRadius: '5px',
  backgroundColor: '#FFFFFF',
  color: lightTheme.palette.text.primary,
  fontFamily: 'Century Gothic',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  cursor: 'pointer',
  position: 'relative',
  borderStyle: 'solid',
  borderColor: type === 'border' ? lightTheme.palette.grey.main : 'transparent',

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}))
const SelectInputObject = ({
  items,
  value,
  handleChange,
  name,
  type,
  register,
}) => {
  return (
    <CustomSelect
      type={type}
      value={value}
      onChange={handleChange}
      IconComponent={() => (
        <FontAwesomeIcon
          icon={faChevronDown}
          style={{
            position: 'absolute',
            right: '20px',
            color: lightTheme.palette.grey.main,
          }}
        />
      )}
      name={name}
      {...register}
    >
      {items &&
        items.map((item, index) => {
          return !item.prix ? (
            <MenuItem key={item.id} value={item}>
              {item.titre} {item.sousTitre}
            </MenuItem>
          ) : (
            <MenuItem key={item.id} value={item}>
              {item.titre} {item.sousTitre} -{' '}
              {(
                item.prix *
                JSON.parse(sessionStorage.getItem('currency')).currentRate
              ).toFixed(2)}{' '}
              {JSON.parse(sessionStorage.getItem('currency')).devise}
            </MenuItem>
          )

          /* (
        <MenuItem key={item.id} value={item.id}>{item.titre} {item.sousTitre}</MenuItem>
      ) */
        })}
    </CustomSelect>
  )
}

export default SelectInputObject
