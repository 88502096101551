import {
  Box,
  CircularProgress,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import moment from 'moment/moment'
import lightTheme from '../../styles/theme/lightTheme'
import * as React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEdit, faTrashCan } from '@fortawesome/pro-regular-svg-icons'
import AppPagination from '../paginations'
import { faHandshake } from '@fortawesome/pro-solid-svg-icons'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ROLE_CANDIDAT } from '../../data'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const DeleteIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.error.dark}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.error.dark}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const ButtonIcon = styled(Box)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `1px solid ${lightTheme.palette.primary.b2}`,
  borderRadius: '3px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  margin: '0 5px',
  float: 'left',
  '&:hover': {
    border: `2px solid ${lightTheme.palette.primary.b2}`,
  },
  '&:active': {
    border: `1px solid ${lightTheme.palette.primary.b2}`,
  },
}))

const TablePanelBox = styled(TabPanel)(({ theme }) => ({
  height: 'calc( 100% - 45px)',
  marginBottom: 10,
  backgroundColor: 'rgb(227, 230, 255, 0.5)',
  '& .MuiBox-root': {
    padding: 0,
  },
}))

const HeaderTab = styled(TableHead)(({ theme }) => ({
  height: 50,
  backgroundColor: lightTheme.palette.primary.b6,
}))

const TitleHead = styled(TableCell)(({ theme }) => ({
  fontFamily: 'Century Gothic Bold',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const BodyTable = styled(TableBody)(({ theme }) => ({
  backgroundColor: '#FFFFFF',
}))

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: '2px solid rgb(227, 230, 255, 0.5)',
  fontFamily: 'Century Gothic',
  fontSize: 10,
  lineHeight: '140%',
  letterSpacing: '0.2px',
  color: lightTheme.palette.text.primary,

  [theme.breakpoints.up('sm')]: {
    fontSize: 12,
  },
}))

const BesoinTable = ({
  besoins,
  loading,
  handleRemove,
  handleEdit,
  handleDetailBesoin,
  handlePageChange,
  count,
  page,
  role,
  showDetail = false,
}) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  if (loading)
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '40vh',
        }}
      >
        <CircularProgress />
      </Box>
    )
  return (
    <>
      <TablePanelBox>
        {besoins && (
          <Table>
            <HeaderTab>
              <TableRow>
                {role !== ROLE_CANDIDAT && showDetail && (
                  <TitleHead>{t('header.company')}</TitleHead>
                )}
                <TitleHead>{t('filter.poste')}</TitleHead>
                <TitleHead>{t('filter.place')}</TitleHead>
                {showDetail && <TitleHead>{t('contact.country')}</TitleHead>}
                <TitleHead>{t('profile.publicationDate')}</TitleHead>
                {role !== ROLE_CANDIDAT && (
                  <TitleHead>{t('profile.matching')}</TitleHead>
                )}
                <TitleHead></TitleHead>
              </TableRow>
            </HeaderTab>
            {besoins &&
              besoins.map((besoin, i) => (
                <BodyTable key={besoin.id}>
                  <TableRow>
                    {role !== ROLE_CANDIDAT && showDetail && (
                      <StyledTableCell>
                        <a
                          href={
                            '/admin/gestion-client/profil-client-entreprise/' +
                            besoin?.entreprise?.profile?.id
                          }
                        >
                          {besoin.entreprise.raisonSocial}
                        </a>
                      </StyledTableCell>
                    )}
                    <StyledTableCell>
                      <a
                        href={'#'}
                        onClick={(e) => {
                          role === 'ROLE_ADMIN'
                            ? navigate(
                                '/admin/gestion-client/matching/' + besoin.id
                              )
                            : handleDetailBesoin(besoin)
                          //e.target.parentNode.parentNode.parentNode.remove()
                        }}
                      >
                        {i18n.language === 'fr'
                          ? besoin.poste.titre
                          : besoin.poste.titreEn}
                      </a>
                    </StyledTableCell>
                    <StyledTableCell>{besoin.lieu}</StyledTableCell>
                    {showDetail && (
                      <StyledTableCell>{besoin.pays}</StyledTableCell>
                    )}
                    <StyledTableCell>
                      {moment(besoin.date)
                        .tz('Europe/Paris')
                        .format('DD/MM/YYYY HH:mm')}
                    </StyledTableCell>
                    {role !== ROLE_CANDIDAT && (
                      <StyledTableCell>{besoin.totalMatch}</StyledTableCell>
                    )}
                    <StyledTableCell>
                      {role !== ROLE_CANDIDAT && (
                        <ButtonIcon
                          onClick={(e) => {
                            navigate(
                              role === 'ROLE_ADMIN'
                                ? '/admin/gestion-client/matching/' + besoin.id
                                : '/user/candidat/matching/' + besoin.id
                            )
                            //e.target.parentNode.parentNode.parentNode.remove()
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faHandshake}
                            color={lightTheme.palette.primary.b2}
                            fontSize={16}
                          />
                        </ButtonIcon>
                      )}
                      <ButtonIcon
                        onClick={(e) => {
                          handleDetailBesoin(besoin)
                          //e.target.parentNode.parentNode.parentNode.remove()
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEye}
                          color={lightTheme.palette.primary.b2}
                          fontSize={16}
                        />
                      </ButtonIcon>
                      {role !== ROLE_CANDIDAT && (
                        <>
                          <ButtonIcon
                            onClick={(e) => {
                              handleEdit(besoin)
                              //e.target.parentNode.parentNode.parentNode.remove()
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faEdit}
                              color={lightTheme.palette.primary.b2}
                              fontSize={16}
                            />
                          </ButtonIcon>
                          <DeleteIcon
                            onClick={(e) => {
                              handleRemove(besoin.id)
                              //e.target.parentNode.parentNode.parentNode.remove()
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faTrashCan}
                              color={lightTheme.palette.error.dark}
                              fontSize={16}
                            />
                          </DeleteIcon>
                        </>
                      )}
                    </StyledTableCell>
                  </TableRow>
                </BodyTable>
              ))}
          </Table>
        )}
      </TablePanelBox>
      <AppPagination
        handlePageChange={handlePageChange}
        count={count}
        page={page}
        pageSize={10}
      />
    </>
  )
}

export default BesoinTable
