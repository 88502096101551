import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { Badge, Box, styled } from '@mui/material'
import lightTheme from '../../styles/theme/lightTheme'
import Api from '../../services/api.service'
import { handlingErrors } from '../../utils'
import { ROLE_CANDIDAT, ROLE_ENTREPRISE } from '../../data'

const SidebarLink = styled(Link)(({ theme }) => ({
  display: 'flex',
  color: lightTheme.palette.grey.light,
  justifyContent: 'space-between',
  alignItems: 'center',
  listStyle: 'none',
  height: 60,
  textDecoration: 'none',
  fontSize: 14,
  fontFamily: 'Century Gothic Bold',
  lineHeight: '140%',
  letterSpacing: '0.2px',
  textTransform: 'uppercase',
}))

const Title = styled(Box)({
  display: 'flex',
  alignItems: 'center',
})

const Icon = styled(Box)({
  width: 25,
  height: 25,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const SidebarLabel = styled('span')(({ theme }) => ({
  marginLeft: '12px',
}))

const DropdownLink = styled(NavLink)({
  height: 60,
  paddingLeft: '10px',
  display: 'flex',
  alignItems: 'center',
  columnGap: '15px',
  textDecoration: 'none',
  color: lightTheme.palette.grey.main,
  fontFamily: 'Century Gothic',
  fontSize: 14,
  lineHeight: '140%',
  letterSpacing: '0.2px',

  '&:hover': {
    color: lightTheme.palette.grey.light,
    cursor: 'pointer',
  },

  '&.active': {
    color: lightTheme.palette.grey.light,
  },
})

const DropdownLabel = styled('span')({})

const Submenu = ({
  item,
  clientEntreprise,
  prospectEntreprise,
  clientCandidat,
  prospectCandidat,
  contact,
  besoin,
}) => {
  const [subnav, setSubnav] = useState(false)

  /*  useEffect(() => {
    setCountCandidat(clientCandidat)
 
  }, [item]) */
  /* 
  console.log(
    clientEntreprise,
    prospectEntreprise,
    clientCandidat,
    prospectCandidat,
    contact
  ) */

  const showSubnav = () => setSubnav(!subnav)
  return (
    <>
      <SidebarLink
        to={item.subNav ? '#' : item.path}
        onClick={item.subNav && showSubnav}
      >
        <Title>
          <Icon>{item.icon}</Icon>
          <SidebarLabel>{item.title}</SidebarLabel>
        </Title>
        <div>
          {item.subNav && subnav
            ? item.iconOpened
            : item.subNav
            ? item.iconClosed
            : null}
        </div>
      </SidebarLink>
      {subnav &&
        item.subNav.map((subitem, index) => {
          return (
            <DropdownLink to={subitem.path} key={index}>
              {subitem.icon}

              <DropdownLabel>
                {subitem.title}
                {'  '}
                {subitem.counter &&
                  item.counter === 'client' &&
                  subitem.counter === 'candidat' && <>({clientCandidat})</>}
                {subitem.counter &&
                  item.counter === 'prospect' &&
                  subitem.counter === 'candidat' && <>({prospectCandidat})</>}
                {subitem.counter &&
                  item.counter === 'client' &&
                  subitem.counter === 'entreprise' && <>({clientEntreprise})</>}
                {subitem.counter &&
                  item.counter === 'prospect' &&
                  subitem.counter === 'entreprise' && (
                    <>({prospectEntreprise})</>
                  )}
                {subitem.counter &&
                  item.counter === 'prospect' &&
                  subitem.counter === 'demande' && <>({contact})</>}
                {subitem.counter &&
                  item.counter === 'client' &&
                  subitem.counter === 'besoin' && <>({besoin})</>}
              </DropdownLabel>
            </DropdownLink>
          )
        })}
    </>
  )
}

export default Submenu
