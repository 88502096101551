import React from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { AdminLayout } from '../components/layouts'
import {
  Candidate,
  Company,
} from '../pages/Admin/User'
import { Dashboard, MenuDashboard } from '../pages/Admin/Dashboard'
import {
  ProfilAdmin,
  GestionAdmins,
  AddAdmin,
  GestionDatas,
} from '../pages/Admin/Admin'
import CandidateProfil from '../pages/Admin/User/Candidate/CandidateProfil'
import { CompanyProfil } from '../pages/Admin/User/Company'
import {
  ListProspectCompany,
  ProspectCompanyProfil,
} from '../pages/Admin/User/Prospect/CompanyProspect'
import {
  ListProspectCandidate,
  ProspectCandidateProfil,
} from '../pages/Admin/User/Prospect/CandidateProspect'
import Calendar from '../pages/Admin/Calendar/Calendar'
import Disponibilities from '../pages/Admin/Calendar/Disponibilities'
import DemandeRdv from '../pages/Admin/User/Prospect/DemandeRdv'
import DemandeRendezVous from '../pages/Admin/Calendar/DemandeRendezVous'
import AddProspectCandidate from '../pages/Admin/User/Prospect/CandidateProspect/AddProspectCandidate'
import AddProspectCompany from '../pages/Admin/User/Prospect/CompanyProspect/AddProspectCompany'
import AddCandidate from '../pages/Admin/User/Candidate/AddCandidate'
import AddCompany from '../pages/Admin/User/Company/AddCompany'
import MatchingCandidate from '../pages/Admin/User/Candidate/MatchingCandidate'
import Besoins from '../pages/Admin/User/Company/Besoins'
import ListeRendezVous from '../pages/Admin/Calendar/ListeRendezVous'
import AteliersList from '../pages/Admin/User/Atelier/AteliersList'
import { AtelierDetail } from '../pages/Admin/User/Atelier'
import { useSelector } from 'react-redux'
import ListeArchive from '../pages/Admin/Archive/ListArchive'

const AdminRouter = () => {
  const currentUser = useSelector((state) => state.userAuth.user)

  const isAdmin = () => {
    return (
      currentUser.data.role === 'ROLE_SUPER_ADMIN' ||
      currentUser.data.role === 'ROLE_MINI_ADMIN' ||
      currentUser.data.role === 'ROLE_ADMIN'
    )
  }

  return (
    <Routes>
      <Route
        element={isAdmin() ? <AdminLayout /> : <Navigate replace to={'/'} />}
      >
        <Route index element={<MenuDashboard />} />

        <Route path={'mon-profil'} element={<Dashboard />} />
        <Route path={'gestion-admins'} element={<GestionAdmins />} />
        <Route path={'gestion-donnees'} element={<GestionDatas />} />

        <Route path={'gestion-admins'}>
          <Route path={'ajouter-admin'} element={<AddAdmin />} />
          <Route path={'profil-admin/:adminId'} element={<ProfilAdmin />} />
        </Route>

        <Route path={'gestion-client'}>
          <Route path={'liste-clients-entreprises'} element={<Company />} />
          <Route
            path={'profil-client-entreprise/:userId'}
            element={<CompanyProfil />}
          />
          <Route path={'ajouter-client-entreprise'} element={<AddCompany />} />
          <Route path={'matching/:besoinId'} element={<MatchingCandidate />} />
          <Route path={'liste-besoins-entreprises'} element={<Besoins />} />
          <Route
            path={'liste-besoins-entreprises/:besoinId'}
            element={<Besoins />}
          />

          <Route path={'liste-clients-candidats'} element={<Candidate />} />
          <Route
            path={'profil-client-candidat/:userId'}
            element={<CandidateProfil />}
          />
          <Route path={'ajouter-client-candidat'} element={<AddCandidate />} />
        </Route>


        <Route path={'archive'} element={<ListeArchive />} />

        <Route path={'gestion-prospect'}>
          <Route
            path={'liste-prospects-entreprises'}
            element={<ListProspectCompany />}
          />
          <Route
            path={'profil-prospect-entreprise/:userId'}
            element={<ProspectCompanyProfil />}
          />
          <Route
            path={'ajouter-prospect-entreprises'}
            element={<AddProspectCompany />}
          />

          <Route
            path={'liste-prospects-candidats'}
            element={<ListProspectCandidate />}
          />
          <Route
            path={'ajouter-prospect-candidats'}
            element={<AddProspectCandidate />}
          />
          <Route
            path={'profil-prospect-candidat/:userId'}
            element={<ProspectCandidateProfil />}
          />

          <Route path={'demandes-de-rendez-vous'} element={<DemandeRdv />} />
        </Route>

        <Route path={'gestion-contenus'}>
          <Route path={'ateliers'} element={<AteliersList />} />
          <Route path={'atelier/:atelierId'} element={<AtelierDetail />} />
        </Route>

        <Route path={'calendrier'}>
          <Route index element={<Calendar />} />
          <Route path={'rendez-vous'} element={<ListeRendezVous />} />
          <Route
            path={'definir-les-disponibilites'}
            element={<Disponibilities />}
          />
          <Route
            path={'demande-de-rendez-vous'}
            element={<DemandeRendezVous />}
          />
        </Route>

        <Route path="*" element={<Navigate replace to={'/'} />} />

      </Route>
    </Routes>
  )
}

export default AdminRouter
