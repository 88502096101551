import React, { useState } from 'react'
import {
  Box,
  CircularProgress,
  FormLabel,
  Grid,
  Modal,
  styled,
  Typography,
} from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'
import '../../styles/components/_grids.scss'
import '../../styles/components/_inputs.scss'
import '../../styles/components/_errors.scss'
import { SelectInput, SelectInputObject } from '../inputs'
import { useTranslation } from 'react-i18next'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 650,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '20px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 700,
    height: 500,
    rowGap: '35px',
  },
}))

const BoxContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 500,
  backgroundColor: lightTheme.palette.primary.b6,
  padding: '20px',

  [theme.breakpoints.up('sm')]: {
    height: 300,
  },
}))

const ChoiceOfferModal = ({
  open,
  onClose,
  openDelete,
  items,
  handleClick,
  handleClickOffreEntreprise,
  handleClickOffreCandidat,
  errors,
  register,
  handleChange,
  value,
  loading,
  role,
  type,
}) => {
  const { t } = useTranslation()
  const durre = [
    { id: 15, text: '15 jours', value: 15 },
    { id: 3, text: '3 Mois', value: 3 },
    { id: 6, text: '6 Mois', value: 6 },
    { id: 12, text: '12 Mois', value: 12 },
  ]
  const [durreSelect, setDurreSelect] = useState(15)
  const handleDureeSelect = (event) => {
    const inputValue = event.target.value
    setDurreSelect(inputValue)
  }

  const status = [
    { id: 1, text: 'Payé', value: 'payer' },
    { id: 2, text: 'À payer', value: 'non-payer' },
  ]
  const [statusSelect, setStatusSelect] = useState('payer')
  const handleStatusSelect = (event) => {
    const inputValue = event.target.value
    setStatusSelect(inputValue)
  }
  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <Typography variant={'subtitle2'} textTransform={'uppercase'}>
          {t('box.title.offer')}
        </Typography>
        <BoxContainer>
          <form>
            <Grid
              container
              spacing={2}
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '5px' }}
              >
                <FormLabel className={'labelForm'}>
                  {t('box.selectOffer')}
                </FormLabel>
                <SelectInputObject
                  items={items}
                  ref={register}
                  name={'func'}
                  handleChange={handleChange}
                  value={value}
                />
                {/*{errors.func && (
                  <Typography className={"error-message"}>
                    {errors.func.message}
                  </Typography>
                )}*/}
              </Grid>
              {role === 'ROLE_ADMIN' && (
                <>
                  {type === 'entreprise' && (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: '5px',
                      }}
                    >
                      <FormLabel className={'labelForm'}>Durée</FormLabel>
                      <SelectInput
                        items={durre}
                        value={durreSelect}
                        defaultValue={durreSelect}
                        handleChange={handleDureeSelect}
                      />
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      rowGap: '5px',
                    }}
                  >
                    <FormLabel className={'labelForm'}>Statut</FormLabel>
                    <SelectInput
                      items={status}
                      value={statusSelect}
                      defaultValue={statusSelect}
                      handleChange={handleStatusSelect}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </form>
        </BoxContainer>
        <Box className={'blockButtons'}>
          <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
            {t('button.cancel')}
          </BorderButtonPurple>
          {loading ? (
            <CircularProgress />
          ) : (
            <CustomButton
              displayIcon={'none'}
              handleClick={() =>
                role === 'ROLE_ADMIN'
                  ? type === 'entreprise'
                    ? handleClickOffreEntreprise(durreSelect, statusSelect)
                    : handleClickOffreCandidat(durreSelect, statusSelect)
                  : handleClick
              }
            >
              {t('button.addOffer')}
            </CustomButton>
          )}
        </Box>
      </ModalContainer>
    </Modal>
  )
}

export default ChoiceOfferModal
