import React from 'react'
import { Box, CircularProgress, Modal, styled, Typography } from '@mui/material'
import { BorderButtonPurple, CustomButton } from '../buttons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleExclamation } from '@fortawesome/pro-regular-svg-icons'
import lightTheme from '../../styles/theme/lightTheme'
import '../../styles/components/_box.scss'

const ModalContainer = styled(Box)(({ theme }) => ({
  width: 'calc(100% - 50px)',
  height: 612,
  backgroundColor: '#FFFFFF',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '30px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  rowGap: '50px',

  '&:focus-visible': {
    outline: 'none',
  },

  [theme.breakpoints.up('sm')]: {
    width: 620,
    height: 450,
    padding: '30px 85px',
  },
}))

const IconBox = styled(Box)(({ theme }) => ({
  width: 85,
  height: 85,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '.iconExclam': {
    color: lightTheme.palette.primary.main,
    fontSize: 80,
  },
}))

const DeleteProfilModal = ({ open, onClose, onConfirm, loading }) => {
  return (
    <Modal
      open={open}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(51, 51, 51, 0.65)',
        },
      }}
    >
      <ModalContainer>
        <IconBox>
          <FontAwesomeIcon
            icon={faCircleExclamation}
            className={'iconExclam'}
          />
        </IconBox>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '20px',
            textAlign: 'center'
          }}
        >
          <Typography variant={'subtitle2'}>
            Êtes-vous sur de vouloir supprimer le profil ?
          </Typography>
          <Typography variant={'body2'}>
          Cette action est réversible, le profil sera supprimé et envoyer aux archives.
          </Typography>
        </Box>
        <Box className={'blockButtons'}>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              <BorderButtonPurple displayIcon={'none'} handleClick={onClose}>
                Non
              </BorderButtonPurple>
              <CustomButton displayIcon={'none'} handleClick={onConfirm}>
                Oui, je supprime le profil
              </CustomButton>
            </>
          )}
        </Box>
      </ModalContainer>
    </Modal>
  )
}

export default DeleteProfilModal
